import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Послуги | Комплексне миття, хімчистка та полірування
			</title>
			<meta name={"description"} content={"Дізнайтеся про всі послуги, які пропонує АвтоКлін: комплексне миття авто, хімчистка салону, полірування кузова, обробка антикорозійними засобами та багато іншого."} />
			<meta property={"og:title"} content={"Послуги | Комплексне миття, хімчистка та полірування"} />
			<meta property={"og:description"} content={"Дізнайтеся про всі послуги, які пропонує АвтоКлін: комплексне миття авто, хімчистка салону, полірування кузова, обробка антикорозійними засобами та багато іншого."} />
			<link rel={"shortcut icon"} href={"https://futureplanbit.com/img/84937.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://futureplanbit.com/img/84937.png"} />
			<meta name={"msapplication-TileImage"} content={"https://futureplanbit.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://futureplanbit.com/img/7.jpg"
						display="block"
						width="auto"
						height="750px"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--green" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
					АвтоКлін
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline1">
						Послуги
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
					Наші послуги - це комплексне миття, хімчистка салону, полірування кузова та багато іншого. Забезпечимо вашому авто бездоганний вигляд.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://futureplanbit.com/img/8.jpg"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
					/>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Комплексне миття авто
				</Text>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Комплексне миття включає в себе зовнішнє миття кузова, миття коліс, очищення скла та дзеркал. Ми використовуємо лише якісні мийні засоби, що не пошкоджують лакофарбове покриття.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://futureplanbit.com/img/9.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
					/>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Хімчистка салону
				</Text>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Наша послуга хімчистки салону включає в себе глибоке очищення оббивки сидінь, ковроліну та всіх внутрішніх поверхонь. Ми використовуємо спеціальні засоби для видалення плям та неприємних запахів.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://futureplanbit.com/img/10.jpg"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Полірування кузова
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Полірування повертає вашому авто первісний блиск та захищає лакофарбове покриття від зовнішніх впливів. Ми використовуємо професійні засоби для досягнення найкращого результату.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});